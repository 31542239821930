/* BreadcrumbOne.css */

.edu-breadcrumb-area {
    width: 100%; /* Full width */
    height: 180px; /* Reduced height */
    background-size: cover;
    background-position: center;
    position: relative;
    margin-top: 20px; /* Reduced top margin */
    padding-top: 80px; /* Reduced padding at the top */
    padding-bottom: 10px; /* Reduced padding at the bottom */
    overflow: hidden; /* Prevent margin collapse */
}

/* Adjust breadcrumb and nav styles */
.edu-breadcrumb-nav {
    margin-top: 5px; /* Reduced margin for nav */
}

.edu-breadcrumb {
    list-style: none;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    color: black;
    align-items: center;
    justify-content: start;
    font-size: 14px; /* Reduced font size */
}

.edu-breadcrumb .breadcrumb-item {
    font-size: 14px; /* Reduced size for breadcrumb items */
    color: black;
    display: inline-block;
}

.edu-breadcrumb .breadcrumb-item a {
    color: black;
    text-decoration: none;
}

.edu-breadcrumb .separator {
    margin: 5px; /* Reduced separator margin */
}

.edu-breadcrumb .separator i {
    color: black;
}

.edu-breadcrumb .breadcrumb-item.active {
    font-size: 14px; /* Reduced size for active breadcrumb item */
}

/* Logo positioning (adjust as needed) */
.breadcrumb-logo {
    max-width: 120px; /* Reduced logo size */
    height: auto;
    display: block;
    margin-top: 10px; /* Reduced margin-top */
    margin-left: auto; /* Center the logo */
}

/* Background shapes */
.shape-dot-wrapper .shape-image {
    position: absolute;
}

.shape-image-1 {
    top: 0;
    left: 10%;
}

.shape-image-2 {
    top: 10%;
    right: 10%;
}

.shape-image-3 {
    bottom: 20%;
    left: 30%;
}

.shape-image-4 {
    bottom: 10%;
    right: 20%;
}

.shape-image-5 {
    bottom: 30%;
    left: 50%;
}

.shape-image-6 {
    top: 50%;
    right: 30%;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
    .edu-breadcrumb-area {
        height: auto; /* Flexible height for content */
        padding-top: 40px; /* Adjusted padding for mobile */
        padding-bottom: 10px;
    }

    .edu-breadcrumb-nav {
        display: flex; /* Use flexbox for alignment */
        align-items: center; /* Vertically align logo and breadcrumb text */
        justify-content: flex-start; /* Align items to the left */
        gap: 10px; /* Add spacing between logo and breadcrumb text */
        flex-wrap: nowrap; /* Prevent wrapping of items */
    }

    .breadcrumb-logo {
        max-width: 80px; /* Adjust logo size for mobile */
        height: auto; /* Maintain aspect ratio */
        margin: 0; /* Remove any unnecessary margins */
    }

    .edu-breadcrumb {
        font-size: 12px; /* Reduced font size for mobile */
    }
}
